import React, {
  createContext,
  Dispatch,
  KeyboardEventHandler,
  MouseEventHandler,
  MutableRefObject,
  PropsWithChildren,
  ReactElement,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { ReadonlyDeep, ReadonlyDeepWithRefs } from "~/src/utils/reflection";
import * as Portal from "@radix-ui/react-portal";
import { useStateRef } from "~/src/hooks/useStateRef";

interface Context {
  isOpen: boolean;
  isOpenRef: ReadonlyDeep<MutableRefObject<boolean>>;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  triggerId: string;
  triggerButtonRef: MutableRefObject<HTMLButtonElement | null>;
  contentRef: MutableRefObject<HTMLDivElement | null>;
  arrowRef: MutableRefObject<SVGSVGElement | null>;
  anchor: Anchor;
  setAnchor: Dispatch<SetStateAction<Anchor>>;
}

interface Position {
  x: number;
  y: number;
}

type Anchor = "center" | "left" | "right";

const Context = createContext<ReadonlyDeepWithRefs<Context> | undefined>(
  undefined,
);

export type RootProps = NonNullable<unknown>;

const windowClickCallbacks: ((this: Window, event: MouseEvent) => void)[] = [];
window.addEventListener(
  "click",
  function (event) {
    for (const callback of windowClickCallbacks) {
      callback.call(this, event);
    }
  },
  { passive: true },
);

const windowResizeCallbacks: ((this: Window, event: UIEvent) => void)[] = [];
window.addEventListener(
  "resize",
  function (event) {
    for (const callback of windowResizeCallbacks) {
      callback.call(this, event);
    }
  },
  { passive: true },
);

export function Root({
  children,
}: ReadonlyDeep<PropsWithChildren<RootProps>>): ReactElement {
  const [isOpen, setIsOpen, isOpenRef] = useStateRef(false);
  const triggerButtonRef = useRef<HTMLButtonElement | null>(null);
  const contentRef = useRef<HTMLDivElement | null>(null);
  const arrowRef = useRef<SVGSVGElement | null>(null);
  // TODO: use useId from React 18
  const triggerId = useMemo(() => makeId(10), []);
  const [anchor, setAnchor] = useState<Anchor>("center");

  return (
    <Context.Provider
      value={{
        isOpen,
        isOpenRef,
        setIsOpen,
        triggerId,
        triggerButtonRef,
        contentRef,
        arrowRef,
        anchor,
        setAnchor,
      }}
    >
      {children}
    </Context.Provider>
  );
}

export interface TriggerProps {
  className?: undefined | string;
}

export function Trigger({
  className,
  children,
}: ReadonlyDeep<PropsWithChildren<TriggerProps>>): ReactElement {
  const context = useContext(Context);
  if (context === undefined) {
    throw new Error("Toggletip.Trigger must be used inside a Toggletip.Root");
  }
  const {
    triggerId,
    isOpen,
    setIsOpen,
    isOpenRef,
    triggerButtonRef,
    contentRef,
    arrowRef,
    setAnchor,
  } = context;

  const onKeyDown = useCallback<KeyboardEventHandler<HTMLButtonElement>>(
    (event) => {
      switch (event.key) {
        case "Escape":
          setIsOpen(false);
          break;

        case "F1":
          setContentPosition(contentRef, triggerButtonRef, setAnchor);
          setIsOpen(true);
          break;

        default:
          return;
      }

      event.preventDefault();
    },
    [contentRef, setAnchor, setIsOpen, triggerButtonRef],
  );

  useEffect(() => {
    function clickCallback(this: Window, event: MouseEvent) {
      if (!isOpenRef.current) {
        return;
      }

      const content = contentRef.current;
      const button = triggerButtonRef.current;
      const arrow = arrowRef.current;
      if (content === null || button === null) {
        return;
      }

      let target = event.target;
      while (target !== null && target instanceof Node) {
        if (target === button || target === content || target === arrow) {
          return;
        }

        target = target.parentNode;
      }

      // The click is elsewhere, let's close the tooltip
      setIsOpen(false);
    }

    windowClickCallbacks.push(clickCallback);
    return () => {
      const index = windowClickCallbacks.indexOf(clickCallback);
      if (index !== -1) {
        windowClickCallbacks.splice(index, 1);
      }
    };
  }, [arrowRef, contentRef, isOpenRef, setIsOpen, triggerButtonRef]);

  useEffect(() => {
    function resizeCallback() {
      if (!isOpenRef.current) {
        return;
      }

      setContentPosition(contentRef, triggerButtonRef, setAnchor);
    }

    windowResizeCallbacks.push(resizeCallback);
    return () => {
      const index = windowResizeCallbacks.indexOf(resizeCallback);
      if (index !== -1) {
        windowResizeCallbacks.splice(index, 1);
      }
    };
  }, [contentRef, isOpenRef, setAnchor, triggerButtonRef]);

  const onClick = useCallback<MouseEventHandler<HTMLButtonElement>>(() => {
    setIsOpen((isOpen) => {
      if (!isOpen) {
        setContentPosition(contentRef, triggerButtonRef, setAnchor);
      }

      return !isOpen;
    });
  }, [contentRef, setAnchor, setIsOpen, triggerButtonRef]);

  className = addClass(className, "toggletip__trigger");

  return (
    <button
      type="button"
      ref={triggerButtonRef}
      className={className}
      aria-controls={triggerId}
      aria-expanded={isOpen}
      onKeyDown={onKeyDown}
      onClick={onClick}
    >
      {children}
    </button>
  );
}

function setContentPosition(
  contentRef: MutableRefObject<HTMLDivElement | null>,
  triggerButtonRef: MutableRefObject<HTMLButtonElement | null>,
  setAnchor: Dispatch<SetStateAction<Anchor>>,
): void {
  const content = contentRef.current;
  const triggerButton = triggerButtonRef.current;

  if (content === null || triggerButton === null) {
    return;
  }

  const triggerButtonAbsolutePosition = getAbsolutePosition(triggerButton);
  const contentWidth = content.offsetWidth;
  let x: number;
  const buttonMiddleX =
    triggerButtonAbsolutePosition.x + triggerButton.offsetWidth / 2;
  if (buttonMiddleX - contentWidth / 2 < 0) {
    x = triggerButtonAbsolutePosition.x;
    setAnchor("left");
  } else if (buttonMiddleX + contentWidth / 2 > window.innerWidth) {
    x = triggerButtonAbsolutePosition.x + triggerButton.offsetWidth;
    setAnchor("right");
  } else {
    x = buttonMiddleX;
    setAnchor("center");
  }
  content.style.setProperty("--mt-content-x", `${x}px`);
  content.style.setProperty(
    "--mt-content-y",
    `${triggerButtonAbsolutePosition.y}px`,
  );
}

export interface ContentProps {
  className?: undefined | string;
}

export function Content({
  className,
  children,
}: ReadonlyDeep<PropsWithChildren<ContentProps>>): ReactElement {
  const context = useContext(Context);
  if (context === undefined) {
    throw new Error("Toggletip.Trigger must be used inside a Toggletip.Root");
  }
  const { triggerId, isOpen, contentRef, triggerButtonRef, anchor, setAnchor } =
    context;
  className = addClass(className, `toggletip__content anchor-${anchor}`);

  useEffect(() => {
    if (isOpen) {
      setContentPosition(contentRef, triggerButtonRef, setAnchor);
    }
  }, [contentRef, isOpen, setAnchor, triggerButtonRef]);

  return (
    <Portal.Root asChild>
      <div ref={contentRef} id={triggerId} className={className} role="status">
        {isOpen && children}
      </div>
    </Portal.Root>
  );
}

export interface ArrowProps {
  className?: undefined | string;
}

export function Arrow({ className }: ReadonlyDeep<ArrowProps>): ReactElement {
  const context = useContext(Context);
  if (context === undefined) {
    throw new Error("Toggletip.Arrow must be used inside a Toggletip.Root");
  }
  const { arrowRef, anchor } = context;

  className = addClass(className, `toggletip__arrow anchor-${anchor}`);

  return (
    <svg
      ref={arrowRef}
      className={className}
      width="10"
      height="5"
      viewBox="0 0 6 2"
      preserveAspectRatio="none"
      aria-hidden="true"
    >
      <polygon points="0,0 6,0 3,2"></polygon>
    </svg>
  );
}

const MAKE_ID_CHARS =
  "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

function makeId(length: number): string {
  let id = "";
  for (let index = 0; index < length; ++index) {
    id += MAKE_ID_CHARS.charAt(
      Math.trunc(Math.random() * MAKE_ID_CHARS.length),
    );
  }

  return id;
}

function addClass(className: string | undefined, newClass: string): string {
  if (className === undefined) {
    return newClass;
  } else {
    return `${className} ${newClass}`;
  }
}

function getAbsolutePosition(element: HTMLElement): Position {
  let x = element.offsetLeft;
  let y = element.offsetTop;

  let parent = element.offsetParent;
  while (parent !== null && parent instanceof HTMLElement) {
    x += parent.offsetLeft;
    y += parent.offsetTop;

    parent = parent.offsetParent;
  }

  return { x, y };
}
